.message-list-other {
    padding: 10px;
    border-radius: 2rem;
    background-color: cornflowerblue;
    width: fit-content;
    align-self: flex-end;
    margin: 5px;
}

.message-list-other-message {
    margin: 0;
}