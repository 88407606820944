.SlideElement {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.SlideOne {
    background-image: linear-gradient(orange, rgb(255, 102, 0));
}

.SlideTwo {
    background-image: linear-gradient(rgb(0, 224, 0), green);
}

.SlideThree {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SlideTitle {
    margin: 0;
    color: white;
    font-size: 6rem;
    text-align: center;
}

.ReviewCard {
    color: white;
    background-image: linear-gradient(rgb(250, 0, 250), rgb(182, 0, 182));
}