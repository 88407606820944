.contact-container {
    display: flex;
    justify-content: center;
}

.contact-form-container {
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding: 30px;
    border-radius: 30px;
}

.contact-form-container h1 {
    margin: 0;
}

.contact-form-form label {
    color: #2E2532;
    font-size: 1.2rem;
}

.contact-form-form input {
    color: #2E2532;
    font-size: 1.2rem;
    max-width: 50rem;
}

.contact-form-form button {
    color: #2E2532;
    font-size: 1.2rem;
    justify-self: center;
}