h1, h2, h3, p {
    font-family: Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
}

.holiday-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1500px;
    margin: 50px auto;
}

.bar-separator {
    height: 3px;
    width: 80%;
    background-color: black;
    margin: auto;
}

.info-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0 50px 0;
}

.footer-logo {
    text-align: center;
    margin: 50px;
}
