.chat-info {
    margin: 0;
    font-size: small;
    text-align: center;
}

.chat-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    width: 50rem;
    height: 35rem;
    overflow: auto;
    margin: auto;
    margin-top: 3rem;
    border-radius: 5px;
}

.chat-message-form {
    text-align: center;
    margin: 50px;
}