.info-header {
    width: 100%;
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
}

.info-header-logo {
    box-sizing: border-box;
    margin: 10px;
    max-height: calc(100% - 20px);
}