.header-container {
    width: 100%;
    height: 3rem;
    background-color: #2E2532;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* High cause slideshow buttons have high z index */
}

.header-container p {
    margin: 4rem;
    color: white;
}

.header-container Button{
    margin-left: 10px;
    margin-right: 10px;
}