.info-card-style {
    color: #2E2532;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 15rem;
    text-align: center;
}

.info-card-icon {
    width: 4rem;
    height: 4rem;
}

.info-card-style h2{
    margin: 10px;
}

.info-card-style p {
    margin: 0;
}
