@import url(https://fonts.googleapis.com/css2?family=Murecho:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
}

p, h1, h2, h3 {
  color: #2E2532;
}
h1, h2, h3, p {
    font-family: Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
}

.holiday-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1500px;
    margin: 50px auto;
}

.bar-separator {
    height: 3px;
    width: 80%;
    background-color: black;
    margin: auto;
}

.info-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0 50px 0;
}

.footer-logo {
    text-align: center;
    margin: 50px;
}

.bheader-container {
    width: 100%;
    max-height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bheader-image {
    min-height: 100%;
    min-width: 100%;
}

.bheader-logocontainer {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bheader-logo {
    max-height: 25rem;
    max-width: 100%;
}

.bheader-logosub {
    color: white;
    font-family: 'Murecho', sans-serif;
    letter-spacing: 3px;
}
.header-container {
    width: 100%;
    height: 3rem;
    background-color: #2E2532;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* High cause slideshow buttons have high z index */
}

.header-container p {
    margin: 4rem;
    color: white;
}

.header-container Button{
    margin-left: 10px;
    margin-right: 10px;
}
.SlideElement {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.SlideOne {
    background-image: linear-gradient(orange, rgb(255, 102, 0));
}

.SlideTwo {
    background-image: linear-gradient(rgb(0, 224, 0), green);
}

.SlideThree {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SlideTitle {
    margin: 0;
    color: white;
    font-size: 6rem;
    text-align: center;
}

.ReviewCard {
    color: white;
    background-image: linear-gradient(rgb(250, 0, 250), rgb(182, 0, 182));
}
.info-header {
    width: 100%;
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
}

.info-header-logo {
    box-sizing: border-box;
    margin: 10px;
    max-height: calc(100% - 20px);
}
.holiday-card-style {
    max-width: 100%;
    width: 18rem;
    height: 17rem;
    margin: 10px;
    background-color: whitesmoke;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    
    transition-duration: 0.25s;}

.holiday-card-style:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.holiday-card-style p, .holiday-card-style h2 {
    color: #2E2532;
    margin: 0;
}

.card-banner {
    width: 100%;
    max-height: calc(17rem * 2/3);
}

.card-info-container {
    padding-left: 10px;
    padding-right: 10px;
}

.info-card-style {
    color: #2E2532;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 15rem;
    text-align: center;
}

.info-card-icon {
    width: 4rem;
    height: 4rem;
}

.info-card-style h2{
    margin: 10px;
}

.info-card-style p {
    margin: 0;
}

.location-card-container {
    margin: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.location-card-style {
    width: 80%;
    height: 20rem;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
}

.location-card-image {
    height: 20rem;
    width: 30%;
}

.location-card-info {
    margin: 2.5rem;
}

.location-card-info {
    margin: 2.5rem;
}

.location-card-info h1 {
    margin: 0;
    margin-bottom: 1rem;
}

.location-card-info p {
    margin: 0;
    font-size: 1.2rem;
}

.location-card-title-container {
    display: flex;
    flex-direction: row;
}

.location-card-title-container img {
    margin-left: 1rem;
    height: 2rem;
}
.staff-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.staff-card-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.staff-card-banner {
    border-radius: 50px;
    margin-top: 30px;
}

.staff-card-subheading-img {
    border-radius: 30px;
    margin: 30px;
    height: 4rem;
}

.staff-card-history-para {
    text-align: center;
    max-width: 70rem;
}
.staff-card-style {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 20rem;
}

.staff-card-image {
    border-radius: 100%;
    width: 10rem;
}

.staff-card-name {
    margin: 3px;
}

.staff-card-title {
    margin: 3px;
}

.staff-card-description {
    margin: 3px;
}
.contact-container {
    display: flex;
    justify-content: center;
}

.contact-form-container {
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px;
    border-radius: 30px;
}

.contact-form-container h1 {
    margin: 0;
}

.contact-form-form label {
    color: #2E2532;
    font-size: 1.2rem;
}

.contact-form-form input {
    color: #2E2532;
    font-size: 1.2rem;
    max-width: 50rem;
}

.contact-form-form button {
    color: #2E2532;
    font-size: 1.2rem;
    justify-self: center;
}
.chat-info {
    margin: 0;
    font-size: small;
    text-align: center;
}

.chat-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    width: 50rem;
    height: 35rem;
    overflow: auto;
    margin: auto;
    margin-top: 3rem;
    border-radius: 5px;
}

.chat-message-form {
    text-align: center;
    margin: 50px;
}
.message-list-self {
    padding: 10px;
    border-radius: 2rem;
    background-color: lightgray;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
    margin: 5px;
}

.message-list-self-message {
    margin: 0;
}
.message-list-other {
    padding: 10px;
    border-radius: 2rem;
    background-color: cornflowerblue;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    margin: 5px;
}

.message-list-other-message {
    margin: 0;
}
