.staff-card-style {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 20rem;
}

.staff-card-image {
    border-radius: 100%;
    width: 10rem;
}

.staff-card-name {
    margin: 3px;
}

.staff-card-title {
    margin: 3px;
}

.staff-card-description {
    margin: 3px;
}