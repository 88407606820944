.holiday-card-style {
    max-width: 100%;
    width: 18rem;
    height: 17rem;
    margin: 10px;
    background-color: whitesmoke;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    
    transition-duration: 0.25s;}

.holiday-card-style:hover {
    transform: scale(1.1);
}

.holiday-card-style p, .holiday-card-style h2 {
    color: #2E2532;
    margin: 0;
}

.card-banner {
    width: 100%;
    max-height: calc(17rem * 2/3);
}

.card-info-container {
    padding-left: 10px;
    padding-right: 10px;
}
