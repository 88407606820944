.message-list-self {
    padding: 10px;
    border-radius: 2rem;
    background-color: lightgray;
    width: fit-content;
    float: left;
    margin: 5px;
}

.message-list-self-message {
    margin: 0;
}