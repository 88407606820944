.staff-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.staff-card-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.staff-card-banner {
    border-radius: 50px;
    margin-top: 30px;
}

.staff-card-subheading-img {
    border-radius: 30px;
    margin: 30px;
    height: 4rem;
}

.staff-card-history-para {
    text-align: center;
    max-width: 70rem;
}