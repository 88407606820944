.location-card-style {
    width: 80%;
    height: 20rem;
    box-shadow: 0px 8px 20px -5px rgba(0,0,0,0.7);
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
}

.location-card-image {
    height: 20rem;
    width: 30%;
}

.location-card-info {
    margin: 2.5rem;
}

.location-card-info {
    margin: 2.5rem;
}

.location-card-info h1 {
    margin: 0;
    margin-bottom: 1rem;
}

.location-card-info p {
    margin: 0;
    font-size: 1.2rem;
}

.location-card-title-container {
    display: flex;
    flex-direction: row;
}

.location-card-title-container img {
    margin-left: 1rem;
    height: 2rem;
}