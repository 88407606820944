.bheader-container {
    width: 100%;
    max-height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bheader-image {
    min-height: 100%;
    min-width: 100%;
}

.bheader-logocontainer {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bheader-logo {
    max-height: 25rem;
    max-width: 100%;
}

.bheader-logosub {
    color: white;
    font-family: 'Murecho', sans-serif;
    letter-spacing: 3px;
}